import { useQuery } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { CMSPageData } from 'types/CMS';
import { fetchThumbnailUrl } from 'utils/mediaUtil';
import { cmsKeys } from '.';
import CMSHelper from '../../helpers/CMSHelper';
import { getLocalizedQualifier } from '../../utils/cmsUtil';
import { fetchCmsHeaderLinks, fetchFooter } from './connector';

const cmsHelper = new CMSHelper();

const useCmsPageData = ({
  enabled = true,
  localizeQualifier = true,
  qualifier,
}: {
  enabled?: boolean;
  localizeQualifier?: boolean;
  qualifier: string;
}) => {
  const { locale } = useRouter();
  const cmsPageQualifier = localizeQualifier ? getLocalizedQualifier(qualifier, locale) : qualifier;

  return useQuery<CMSPageData>({
    enabled: !!enabled && !!cmsPageQualifier && !!qualifier,
    queryFn: () => cmsHelper.getCMSPage(cmsPageQualifier, locale),
    queryKey: cmsKeys.cmsPage(cmsPageQualifier, locale),
  });
};

const useVideoThumbnailUrl = (videoUrl: string) =>
  useQuery({
    queryFn: async () => await fetchThumbnailUrl(videoUrl),
    queryKey: cmsKeys.videoThumbnailUrl(videoUrl),
  });

const useFooter = () => {
  const { locale } = useRouter();

  return useQuery({
    queryFn: () => fetchFooter(locale),
    queryKey: cmsKeys.footer(locale),
    select: (data) => data?.orderedSlots,
  });
};

const useCmsHeaderLinks = () => {
  const { locale } = useRouter();
  return useQuery({
    queryFn: () => fetchCmsHeaderLinks(locale),
    queryKey: cmsKeys.cmsHeaderLinks(locale),
  });
};

export { useCmsHeaderLinks, useCmsPageData, useFooter, useVideoThumbnailUrl };

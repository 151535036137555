import Backdrop from 'components/Backdrop/Backdrop';
import styled, { css } from 'styled-components';
import { breakpointUp, spacer, specific, variable, zIndex } from 'theme';
import { BREAKPOINTS } from '../../constants/breakpoints';

interface StyledHeaderProps {
  fixed: boolean;
}

export const StyledHeader = styled.header<StyledHeaderProps>`
  position: sticky;
  top: 0;
  left: 0;
  transition: ${variable('transition')};

  ${({ fixed }) =>
    fixed &&
    css`
      box-shadow: ${variable('box-shadow-soft')};

      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          box-shadow: none;
        `,
      )}
    `}

  @media print {
    display: none;
  }
`;

export const StyledBackdrop = styled(Backdrop)`
  && {
    z-index: ${zIndex('backdrop-header')};
  }
`;

export const StyledEmptyHeaderBar = styled.div`
  padding: ${spacer(100)};
  background-color: ${specific('header', 'empty-banner')};
`;

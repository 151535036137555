import useAlgoliaAutoComplete from 'hooks/useAlgoliaAutocomplete';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import useWindowSize from 'hooks/useWindowSize';
import dynamic from 'next/dynamic';
import { memo, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { useAutocompleteRefs } from '../Hooks/useAutocompleteRefs';
import { StyledAutocomplete } from './Autocomplete.styled';
import AutocompleteSearchInput from './AutocompleteSearchInput/AutocompleteSearchInput';

const AutocompleteSearchPanel = dynamic(() => import('./AutocompleteSearchPanel/AutocompleteSearchPanel'));

const variants = {
  closed: () => ({
    y: '0%',
  }),
  open: (isMobile: boolean) => ({
    y: isMobile ? '-80%' : '0%',
  }),
};

const transition = {
  duration: 0.3,
  ease: [0.15, 1, 0.3, 1],
};

const Autocomplete = () => {
  const { isMobile } = useWindowSize();

  const { autocomplete, autocompleteState } = useAlgoliaAutoComplete();
  const { getEnvironmentProps } = autocomplete;
  const { formRef, inputRef, panelRef } = useAutocompleteRefs({ getEnvironmentProps });
  const containerRef = useRef<HTMLDivElement>(null);

  const isOpen = !!autocompleteState?.isOpen;

  const closeAutoComplete = () => {
    autocomplete.setIsOpen(false);
    inputRef?.current?.blur();
  };

  useLockBodyScroll({ condition: isOpen && isMobile });
  useOnClickOutside(containerRef, closeAutoComplete);

  return (
    <StyledAutocomplete
      $isDropdownOpen={isOpen}
      animate={isOpen ? 'open' : 'closed'}
      custom={isMobile}
      initial="closed"
      transition={transition}
      variants={variants}
      ref={containerRef}
      {...autocomplete.getRootProps({})}
    >
      <AutocompleteSearchInput
        ref={formRef}
        autocomplete={autocomplete}
        autocompleteState={autocompleteState}
        inputRef={inputRef}
        showPanel={isOpen}
      />

      {isOpen && (
        <AutocompleteSearchPanel
          ref={panelRef}
          autocomplete={autocomplete}
          autocompleteState={autocompleteState}
          showPanel={isOpen}
          closeAutoComplete={closeAutoComplete}
        />
      )}
    </StyledAutocomplete>
  );
};

export default memo(Autocomplete);

import useRouter from 'hooks/useRouter';
import { useRouterParam } from 'hooks/useRouterParam';
import { pathnames } from 'i18n/pathnames';
import { DEFAULT_LOCALE } from '../../../constants/i18n';
import useLocalizedUrls from '../../../hooks/useLocalizedUrls';
import Typography from '../../Typography/Typography';
import { StyledLink } from './LanguageSwitcher.styled';

interface LanguageSwitcherProps {
  className?: string;
  showDivider?: boolean;
}

// !Important: We don't use the next/link here because it will prefetch the page and change the locale in the i18n middleware.
// Even prefetch={false} doesn't work because it will still prefetch on hover for the pages router
// Source: https://nextjs.org/docs/pages/api-reference/components/link#prefetch

const LanguageSwitcher = ({ className, showDivider = true }: LanguageSwitcherProps) => {
  const router = useRouter();
  const { locale, locales, pathname } = router;
  const searchQuery = useRouterParam('q');
  const localesToShow = locales?.filter((filterLocale) => filterLocale !== locale && filterLocale !== DEFAULT_LOCALE);

  const { getLocalizedPathnameByLocale } = useLocalizedUrls();

  const getLocalizedUrl = (newLocale: string) => {
    const localizedPathname = getLocalizedPathnameByLocale(newLocale);
    if (pathname === pathnames.SEARCH && searchQuery) {
      return `${localizedPathname}?q=${searchQuery}`;
    }
    return localizedPathname;
  };

  if (!localesToShow?.length) {
    return null;
  }

  return (
    <>
      {localesToShow.map((newLocale) => (
        <StyledLink
          key={newLocale}
          className={className}
          href={getLocalizedUrl(newLocale)}
          lang={newLocale}
          showDivider={showDivider}
        >
          <Typography color="">{newLocale.toUpperCase()}</Typography>
        </StyledLink>
      ))}
    </>
  );
};

export default LanguageSwitcher;

import styled from 'styled-components';
import { spacer, specific } from 'theme';
import Typography from '../../../../Typography/Typography';

export const StyledCartButtonCountWrapper = styled.div`
  position: absolute;
  left: -8px;
  top: -8px;
  background-color: ${specific('cart', 'cart-count-background')};
  width: ${spacer(125)};
  height: ${spacer(125)};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCartButtonCountWrapperText = styled(Typography)`
  color: ${specific('cart', 'cart-count-color')};
`;

import { createAutocomplete } from '@algolia/autocomplete-core';

import { useQuerySuggestions } from 'features/search/queries';
import { pathnames } from 'i18n/pathnames';
import { useMemo, useState } from 'react';
import { AlgoliaAutocompleteItem, AlgoliaAutocompleteState } from 'types/Algolia';
import {
  analyticsTagsPlugin,
  getAlgoliaProductSearchPlugin,
  getQuerySuggestionsPlugin,
  getRedirectUrlPlugin,
} from 'utils/algoliaPlugins';
import { getSearchUrl } from 'utils/algoliaUtil';
import { useAlgoliaSearchClient } from './useAlgoliaSearchClient';
import useRouter from './useRouter';

const useAlgoliaAutocomplete = () => {
  const router = useRouter();
  const { locale, pathname, query } = router;

  const [autocompleteState, setAutocompleteState] = useState<AlgoliaAutocompleteState>();

  const { searchClient } = useAlgoliaSearchClient();

  const { data: emptyQuerySuggestions, isFetched: emptyQuerySuggestionsIsFetched } = useQuerySuggestions();

  const querySuggestionsPlugin = getQuerySuggestionsPlugin(locale, emptyQuerySuggestions ?? [], searchClient);
  const algoliaProductSearchPlugin = getAlgoliaProductSearchPlugin(locale, searchClient);
  const redirectUrlPlugin = getRedirectUrlPlugin(router);

  const plugins = [querySuggestionsPlugin, analyticsTagsPlugin, algoliaProductSearchPlugin, redirectUrlPlugin].filter(
    Boolean,
  );

  const isSearchPage = pathname === pathnames.SEARCH;

  const autocomplete = useMemo(
    () =>
      createAutocomplete<AlgoliaAutocompleteItem, React.BaseSyntheticEvent, React.MouseEvent, React.KeyboardEvent>({
        id: 'autocomplete',
        initialState: {
          query: isSearchPage ? query?.q?.toString() : undefined,
        },
        navigator: {
          navigate({ itemUrl }) {
            router.push(itemUrl);
          },
        },
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        onSubmit: ({ state }) => {
          const redirect = state.collections.find(
            (collection) => !!collection.items.length && collection.source.sourceId === 'redirectUrlPlugin',
          );

          if (redirect) {
            return router.push(redirect.items[0].urls[0]);
          }
          router.push(getSearchUrl(state.query, locale));
        },
        openOnFocus: true,
        plugins,
      }),
    [emptyQuerySuggestionsIsFetched, searchClient],
  );

  return { autocomplete, autocompleteState };
};

export default useAlgoliaAutocomplete;

export const ALGOLIA_SOURCE_IDS = {
  PRODUCTS_SEARCH: 'products_search',
  QUERY_SUGGESTIONS_PLUGIN: 'querySuggestionsPlugin',
  REDIRECT_URL_PLUGIN: 'redirectUrlPlugin',
};

export const ALGOLIA_MAX_RESULTS = {
  CATEGORY: 3,
  FACETS: 100,
  PRODUCTS: 3,
};

export const ALGOLIA_EVENT_KEYS: Record<string, string> = {
  ADD_TO_CART_CONVERSION: 'conversion',
  CLICK_AFTER_SEARCH: 'click',
  GET_USER_TOKEN: 'getUserToken',
  INIT: 'init',
  PRODUCT_ADD_TO_CART_PDP: '[PDP] - Product Added to Cart After Search',
  PRODUCT_ADD_TO_CART_PLP: '[PLP] - Product Added to Cart After Search',
  PRODUCT_CLICK: '[PLP] - Product Clicked After Search',
  PRODUCT_CLICK_AUTOCOMPLETE: '[Autocomplete] - Product Clicked After Search',
  PRODUCT_VIEW_AUTOCOMPLETE: '[Autocomplete] - Product View',
  SET_USER_TOKEN: 'setUserToken',
} as const;

export const ALGOLIA_SEARCH_NAME = 'algolia_search_name';

export const ALGOLIA_LIMITED_APP_KEY = process.env.NEXT_PUBLIC_ALGOLIA_LIMITED_APP_KEY || '';
export const ALGOLIA_PUBLIC_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '';
export const ALGOLIA_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_INDEX || '';

export const ALGOLIA_RANGE_DELIMITER = ' TO ';

export const WHITE_LISTED_IPS = [
  // KREFEL
  '194.78.26.30',
  '194.78.215.244',
  // HIFI
  '212.24.212.194',
  // TONES
  '213.211.152.224',
  '185.95.74.220',
  '84.197.142.173',
  // VPN
  '94.143.189.240/29',
];

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { BREAKPOINTS } from '../../../constants/breakpoints';
import { breakpointDown, spacer, variable, zIndex } from '../../../theme';

interface StyledAutocompleteProps {
  $isDropdownOpen: boolean;
}

export const StyledAutocomplete = styled(motion.div)<StyledAutocompleteProps>`
  position: relative;
  z-index: ${zIndex('header-overwrite')};

  .formGroup {
    margin-bottom: 0px;
    width: 100%;
  }

  .formGroup-input {
    padding: ${spacer(100)} ${spacer(100)} ${spacer(75)};

    border-bottom-left-radius: ${({ $isDropdownOpen }) => $isDropdownOpen && '0px'};
    border-bottom-right-radius: ${({ $isDropdownOpen }) => $isDropdownOpen && '0px'};

    &:focus {
      ${variable('border')};
    }
  }

  ${breakpointDown(
    BREAKPOINTS.MD,
    ({ $isDropdownOpen }: StyledAutocompleteProps) =>
      $isDropdownOpen &&
      css`
        top: inherit;
        transform: translateY(-80%);
        padding: ${spacer(100)};
        background-color: white;
        left: 0;
        right: 0;
      `
  )}
`;

import useRouter from 'hooks/useRouter';
import { signOut, useSession } from 'next-auth/react';
import { useDispatch } from 'react-redux';
import { clearCart } from 'redux/modules/cart';
import { useAuthActions } from 'store/auth';
import { Credentials } from 'types/Auth';
import { pathnames } from '../i18n/pathnames';
import { log } from '../utils/loggerUtil';

const useCustomSession = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { data: nextAuthSession, status: nextAuthStatus } = useSession();
  const { login, logout } = useAuthActions();

  type LogInUserProps = {
    callback?: () => void;
    callbackError?: () => void;
    credentials: Credentials;
  };
  const loginUser = async ({ callback = () => null, callbackError = () => null, credentials }: LogInUserProps) => {
    try {
      await login(credentials);
      await callback();
    } catch (e) {
      log('[useSession]', 'There was en error logging the user in', e);
      callbackError();
    }
  };

  type LogoutUserProps = {
    callback?: () => void;
    callbackError?: () => void;
  };
  const logoutUser = async ({ callback = () => null, callbackError = () => null }: LogoutUserProps) => {
    try {
      if (nextAuthSession && nextAuthStatus === 'authenticated') {
        await signOut({ redirect: false });
      }
      await logout();
      dispatch(clearCart());
      await callback();
      if (router.pathname !== pathnames.INDEX) {
        router.push(pathnames.INDEX);
      }
    } catch (e) {
      log('[useSession]', 'There was en error logging the user out', e);
      callbackError();
    }
  };

  return {
    login: loginUser,
    logout: logoutUser,
  };
};

export default useCustomSession;

import { MAIN_BAR_CONTAINER_ID } from 'constants/components';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import { Cart } from 'types/Cart';
import { ICON_NAMES } from '../../../constants/icon';
import { PAGE_LAYOUTS, PageLayoutType } from '../../../constants/pageType';
import { pathnames } from '../../../i18n/pathnames';
import Autocomplete from '../../Algolia/Autocomplete/Autocomplete';
import LanguageSwitcher from '../../Language/LanguageSwitcher/LanguageSwitcher';
import ShopLogo from '../../Layout/ShopLogo/ShopLogo';
import AccountMenuDropdown from './Account/AccountMenuDropdown/AccountMenuDropdown';
import {
  StyledMainBar,
  StyledMainBarActions,
  StyledMainBarButtonCart,
  StyledMainBarCategoryButton,
  StyledMainBarCategoryButtonWrapper,
  StyledMainBarContainer,
  StyledMainBarContainerProps,
  StyledMainBarHamburger,
  StyledMainBarLogoLink,
  StyledMainBarStoreButton,
  StyledMainBarTopRow,
} from './MainBar.styled';

const CategoryDropDown = dynamic(() => import('./Category/CategoryDropDown/CategoryDropDown'));

interface MainBarProps extends StyledMainBarContainerProps {
  cart?: Cart;
  hideAccount: boolean;
  hideLanguageSwitcher: boolean;
  inStore: boolean;
  pageLayoutType?: PageLayoutType;
  showAccountDropDown: boolean;
  showCategoryDropDown: boolean;
  toggleAccountDropDown: () => void;
  toggleCategoryDropDown: () => void;
  toggleMobileMenu: () => void;
}

const MainBar = ({
  cart,
  hideAccount,
  hideLanguageSwitcher,
  inStore,
  minimal,
  pageLayoutType = PAGE_LAYOUTS.DEFAULT_LAYOUT,
  showAccountDropDown,
  showCategoryDropDown,
  toggleAccountDropDown,
  toggleCategoryDropDown,
  toggleMobileMenu,
}: MainBarProps) => {
  const { formatMessage } = useIntl();

  const shopLogoUrl =
    pageLayoutType === PAGE_LAYOUTS.CHECKOUT_LAYOUT && !!cart?.isPaymentCart ? undefined : pathnames.INDEX;

  return (
    <StyledMainBarContainer id={MAIN_BAR_CONTAINER_ID} minimal={minimal}>
      <StyledMainBar>
        <StyledMainBarTopRow className="mx-auto grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-4">
          <div className="flex h-full items-center gap-6">
            {!inStore && !minimal && (
              <div
                aria-label={formatMessage({ id: 'main_bar_menu_label' })}
                onClick={() => toggleMobileMenu()}
                onKeyPress={() => toggleMobileMenu()}
                role="button"
                tabIndex={0}
              >
                <StyledMainBarHamburger color="" type="fa" name={ICON_NAMES.BARS} />
              </div>
            )}

            <StyledMainBarLogoLink url={shopLogoUrl}>
              <ShopLogo />
            </StyledMainBarLogoLink>

            {!minimal && (
              <StyledMainBarCategoryButtonWrapper>
                <StyledMainBarCategoryButton toggleCategoryDropDown={toggleCategoryDropDown} />
              </StyledMainBarCategoryButtonWrapper>
            )}
          </div>

          <div className="md:order-last">
            <StyledMainBarActions>
              {!hideLanguageSwitcher && <LanguageSwitcher className={`${!minimal && 'hidden md:block'}`} />}
              {!minimal && (
                <>
                  {!hideAccount && !inStore && (
                    <AccountMenuDropdown show={showAccountDropDown} toggleAccountDropDown={toggleAccountDropDown} />
                  )}
                  <StyledMainBarStoreButton />
                  <StyledMainBarButtonCart cart={cart} />
                </>
              )}
            </StyledMainBarActions>
          </div>

          {!minimal && (
            <div className="col-span-full items-center md:col-span-1">
              <Autocomplete />
            </div>
          )}
        </StyledMainBarTopRow>

        <CategoryDropDown showCategoryDropDown={showCategoryDropDown} toggleCategoryDropDown={toggleCategoryDropDown} />
      </StyledMainBar>
    </StyledMainBarContainer>
  );
};

export default MainBar;

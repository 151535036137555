import useRouter from 'hooks/useRouter';
import { ReactNode } from 'react';
import { CMSLink as CMSLinkType } from 'types/CMS';
import { getPageNameFormUrl } from '../../../../utils/urlUtil';
import Link from '../../../Link/Link';

interface CMSLinkProps {
  callback?: () => void;
  children: ReactNode;
  className?: string;
  linkComponent: CMSLinkType;
}

const CMSLink = ({ callback, children, className, linkComponent }: CMSLinkProps) => {
  const router = useRouter();

  if (linkComponent?.url && linkComponent?.localizedName) {
    const currentLocation = router?.pathname;
    const pageName = getPageNameFormUrl(currentLocation);
    let isActive = false;

    if (pageName && pageName === 'index' && linkComponent.url === '/') {
      isActive = true;
    } else if (currentLocation?.endsWith(linkComponent.url)) {
      isActive = true;
    }

    return (
      <Link className={`${isActive ? 'active' : ''} ${className}`} onClick={callback} url={linkComponent.url}>
        {linkComponent.localizedName}
        {children}
      </Link>
    );
  }

  if (linkComponent?.externalUrl && linkComponent?.localizedName) {
    return (
      <Link className={className} newTab url={linkComponent.externalUrl}>
        {linkComponent.localizedName}
        {children}
      </Link>
    );
  }
  return null;
};

export default CMSLink;

import { LocalizedUrl } from 'types/Url';
import { localizedPathnames } from '../i18n/pathnames';

const excludedPaths = ['/homepage-nl', '/homepage-fr'];

export const getLocalizedUrl = (localizedUrls: LocalizedUrl[], locale: string) => {
  if (!localizedUrls || localizedUrls.some((url) => excludedPaths.includes(url.value))) {
    return undefined;
  }

  return localizedUrls?.find((url) => url.key === locale)?.value || undefined;
};

type GetLocalizedPathnameParams = {
  locale: string;
  pathname: string;
};

export const getLocalizedPathname = ({ locale, pathname }: GetLocalizedPathnameParams) => {
  const localizedPathname = localizedPathnames[pathname]?.[locale];
  // If the localized pathname is not found, chances are that the pathname is already localized
  return localizedPathname ?? pathname;
};

export const excludeDefaultLocale = (locales: string[]) => locales?.filter((locale) => locale !== 'default');

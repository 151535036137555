import CMSLinks from 'components/CMS/CMSLinks/CMSLinks';
import Link from 'components/Link/Link';
import CMSPageLink from 'components/Router/Link/CMSPageLink/CMSPageLink';
import { CMS_COMPONENT_TYPES } from 'constants/cms';
import { useCmsHeaderLinks } from 'features/cms/queries';
import useAuthResolver from 'hooks/useAuthResolver';
import { forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  StyledDeliveryText,
  StyledTopBar,
  StyledTopBarContainer,
  StyledTopBarNavigationLeft,
  StyledTopBarNavigationRight,
} from './TopBar.styled';

const TopBar = forwardRef<HTMLDivElement>((_, ref) => {
  const { formatMessage } = useIntl();
  const extraHeaderLinks = [];

  const { data: cmsHeaderLinks } = useCmsHeaderLinks();
  const { user } = useAuthResolver();

  const uspObject = useMemo(() => {
    const uspSlot = cmsHeaderLinks?.orderedSlots?.find((slot) => slot.position === 'center');

    return uspSlot?.components?.find((uspComponent) => uspComponent?.type === CMS_COMPONENT_TYPES.CMS_USP_COMPONENT);
  }, [cmsHeaderLinks]);

  if (user?.isSupplier) {
    extraHeaderLinks.push(<Link url="suppliers">{formatMessage({ id: 'header_top_nav_suppliers' })}</Link>);
  }

  return (
    <StyledTopBarContainer ref={ref}>
      <StyledTopBar className="relative mx-auto hidden justify-between px-4 py-2 md:flex">
        <StyledTopBarNavigationLeft>
          <CMSLinks linkSlots={cmsHeaderLinks?.orderedSlots} position="left" />
        </StyledTopBarNavigationLeft>

        {uspObject?.type === CMS_COMPONENT_TYPES.CMS_USP_COMPONENT && (
          <CMSPageLink page="termsOfServiceFreeDelivery">
            <StyledDeliveryText uspComponent={uspObject} />
          </CMSPageLink>
        )}

        <StyledTopBarNavigationRight>
          <CMSLinks extraLinks={extraHeaderLinks} linkSlots={cmsHeaderLinks?.orderedSlots} position="right" />
        </StyledTopBarNavigationRight>
      </StyledTopBar>
    </StyledTopBarContainer>
  );
});

export default TopBar;

import { VIMEO_REGEX, YOURUBE_REGEX } from 'constants/media';
import { fetchVimeoVideoData } from 'features/cms/connector';
import { Image } from 'types/Image';
import { SimpleMedia, VideolyMedia } from 'types/Media';
import { ProductVideo } from 'types/Product';

export const getYoutubeVideoIdFromUrl = (url: string) => {
  const regex =
    /(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;

  const match = url.match(regex);

  return Array.isArray(match) && match.length > 7 ? match?.[7] : null;
};

export const getYoutubeThumbnailUrl = (url: string) => {
  const videoId = getYoutubeVideoIdFromUrl(url);

  if (!videoId) return null;

  return `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
};

export const fetchThumbnailUrl = async (videoUrl: string) => {
  const isYoutube = YOURUBE_REGEX.test(videoUrl);
  const isVimeo = VIMEO_REGEX.test(videoUrl);

  if (isVimeo) {
    const result = await fetchVimeoVideoData(videoUrl);
    return result?.thumbnail_url;
  } else if (isYoutube) {
    return getYoutubeThumbnailUrl(videoUrl);
  }

  return null;
};

export const mapImage = (image: Image, alt: string): SimpleMedia | undefined => {
  if (!image.url) return undefined;

  return { alt, imageSrc: image.url };
};

export const mapVideo = (video: ProductVideo, alt: string, onClick: () => void): SimpleMedia | undefined => {
  const imageSrc = getYoutubeThumbnailUrl(video.url);

  if (!imageSrc) return undefined;

  return {
    alt,
    imageSrc,
    onClick,
    videoUrl: video.url,
  };
};

export const mapVideolyVideo = (video: VideolyMedia, alt: string, onClick: () => void): SimpleMedia | undefined => {
  const thumbs = video?.thumbs;
  const imageSrc = thumbs?.high?.url ?? thumbs?.medium?.url ?? thumbs?.default?.url;

  if (!imageSrc) return undefined;

  return {
    alt,
    imageSrc: imageSrc,
    onClick,
  };
};

export const reduceMediaList = <T>(items: T[], mapper: (item: T) => SimpleMedia | undefined) =>
  items.reduce((acc: SimpleMedia[], curr) => {
    const mappedItem = mapper(curr);

    if (mappedItem) {
      acc.push(mappedItem);
    }

    return acc;
  }, []);

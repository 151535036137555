import Icon from 'components/Icon/Icon';
import Typography from 'components/Typography/Typography';
import { ICON_NAMES, ICON_STYLES } from 'constants/icon';
import { useIntl } from 'react-intl';

interface CategoryButtonProps {
  className?: string;
  toggleCategoryDropDown: () => void;
}

const CategoryButton = ({ className, toggleCategoryDropDown }: CategoryButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <Typography
      aria-label={formatMessage({ id: 'categories_button_label' })}
      className={className}
      fontFamily="secondary"
      fontWeight="bold"
      id="category-button"
      onClick={toggleCategoryDropDown}
      role="button"
      tabIndex={0}
    >
      {formatMessage({ id: 'header_main_nav_categories' })}
      <Icon
        type="fa"
        className="arrow"
        iconStyling={ICON_STYLES.SOLID}
        name={ICON_NAMES.ANGLE_DOWN}
        size={150}
        color="primary-300"
      />
    </Typography>
  );
};

export default CategoryButton;

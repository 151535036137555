import { useWebConfig } from 'features/configuration/queries';
import Container from 'ui/Container/Container';
import { TextLink } from 'ui/Link/Link';
import { cn } from 'utils/cn';

type SuggestionsBarProps = { className?: string };

const SuggestionsBar = ({ className }: SuggestionsBarProps) => {
  const { data: webConfig } = useWebConfig();
  const suggestions = webConfig?.suggestions?.filter((suggestion) => suggestion?.url);
  const configuration = webConfig?.suggestionsBarConfiguration;

  if (!suggestions?.length) {
    return null;
  }

  return (
    <div
      className={cn('relative z-base h-8 w-full overflow-x-auto bg-suggestionsBar-background', className)}
      style={{
        backgroundColor: configuration?.backgroundColor,
      }}
    >
      <Container className="flex items-center gap-3">
        {suggestions.map(
          (suggestion) =>
            suggestion?.url && (
              <TextLink
                key={`${suggestion.displayText}`}
                className="text-sm font-bold text-suggestionsBar-foreground hover:text-suggestionsBar-foreground"
                href={suggestion.url}
                style={{ color: configuration?.color }}
              >
                {suggestion.displayText}
              </TextLink>
            ),
        )}
      </Container>
    </div>
  );
};

export default SuggestionsBar;

import styled, { css } from 'styled-components';
import { breakpointDown } from 'theme';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import Button from '../../../Button/Button';

export const StyledCancelButton = styled(Button)`
  display: none;

  ${breakpointDown(
    BREAKPOINTS.MD,
    css`
      display: flex;
    `,
  )}
`;

import { useSearchSuggestions } from 'features/search/queries';
import useRouter from 'hooks/useRouter';
import { Ref, RefObject, forwardRef, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useTypewriter } from 'react-simple-typewriter';
import { ALGOLIA_SEARCH_NAME } from '../../../../constants/algolia';
import { ICON_NAMES } from '../../../../constants/icon';
import { AlgoliaAutocomplete, AlgoliaAutocompleteState } from '../../../../types/Algolia';
import Flex from '../../../Flex/Flex';
import FormGroupInput from '../../../Form/FormGroupInput/FormGroupInput';
import Icon from '../../../Icon/Icon';
import { StyledCancelButton } from './AutocompleteSearchInput.styled';

const DEFAULT_INTERVAL = 5000;

interface AutocompleteSearchInputProps {
  autocomplete: AlgoliaAutocomplete;
  autocompleteState?: AlgoliaAutocompleteState;
  inputRef: RefObject<HTMLInputElement>;
  showPanel: boolean;
}

const AutocompleteSearchInput = forwardRef(
  (
    { autocomplete, autocompleteState, inputRef, showPanel }: AutocompleteSearchInputProps,
    ref: Ref<HTMLFormElement>,
  ) => {
    const { locale } = useRouter();
    const { formatMessage } = useIntl();
    const hasValue = autocompleteState?.query || inputRef.current?.value;

    const defaultLabel = formatMessage({
      id: 'header_search_placeholder',
    });

    const [label, setLabel] = useState(defaultLabel);

    const { data: searchSuggestionsData } = useSearchSuggestions();
    const interval = searchSuggestionsData?.interval ?? 0;

    const [suggestionTypeWriter] = useTypewriter({
      delaySpeed: interval * 1000 || DEFAULT_INTERVAL,
      loop: false,
      words: searchSuggestionsData?.suggestions?.length ? searchSuggestionsData?.suggestions : [''],
    });

    const resetAutoComplete = () => {
      autocomplete.setQuery('');
      autocomplete.setIsOpen(false);
    };

    useEffect(() => {
      if (searchSuggestionsData?.suggestions?.length && suggestionTypeWriter) {
        setLabel(`${defaultLabel} ${suggestionTypeWriter}`);
      }
    }, [searchSuggestionsData, suggestionTypeWriter, locale]);

    useEffect(() => {
      resetAutoComplete();
      setLabel(defaultLabel);
    }, [locale]);

    return (
      <Form
        initialValues={{
          [ALGOLIA_SEARCH_NAME]:
            autocompleteState?.completion ||
            autocompleteState?.query ||
            autocomplete.getInputProps({
              inputElement: null,
            }).value,
        }}
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
        render={() => (
          <form ref={ref} {...autocomplete.getFormProps({ inputElement: inputRef.current })}>
            <Flex fullWidth gap={50}>
              <FormGroupInput
                ref={inputRef}
                inputProps={autocomplete.getInputProps({
                  inputElement: null,
                })}
                label={!hasValue && label ? label : ''}
                labelProps={autocomplete.getLabelProps({})}
                name={ALGOLIA_SEARCH_NAME}
                prependedContent={
                  <div
                    aria-label={formatMessage({
                      id: 'algolia_search_name_label',
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      resetAutoComplete();
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {hasValue ? (
                      <div>
                        <Icon type="custom" color="primary-400" name={ICON_NAMES.TIMES} size={100} />
                      </div>
                    ) : (
                      <Icon type="custom" color="primary-400" name={ICON_NAMES.SEARCH} />
                    )}
                  </div>
                }
              />
              {showPanel && (
                <StyledCancelButton
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    autocomplete.setIsOpen(false);
                  }}
                  rounded={false}
                  text
                >
                  {formatMessage({ id: 'general-cancel' })}
                </StyledCancelButton>
              )}
            </Flex>
          </form>
        )}
      />
    );
  },
);

export default AutocompleteSearchInput;

import * as React from 'react';
import { cn } from 'utils/cn';

type Props = {
  children: React.ReactNode;
  className?: string;
  tabIndex?: number;
  tag?: 'main' | 'nav' | 'footer';
};

const Container = React.forwardRef<HTMLDivElement, Props>(({ children, className, tabIndex, tag, ...props }, ref) => {
  const Tag = tag || 'div';

  return (
    <Tag
      {...props}
      tabIndex={tabIndex}
      ref={ref}
      className={cn(
        'mx-auto w-full px-3 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] xxl:max-w-[1440px]',
        className,
      )}
    >
      {children}
    </Tag>
  );
});

export default Container;

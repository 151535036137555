import { useEffect, useRef } from 'react';
import { AlgoliaAutocomplete } from 'types/Algolia';

interface UseAutocompleteRefsParams {
  getEnvironmentProps: AlgoliaAutocomplete['getEnvironmentProps'];
}

export const useAutocompleteRefs = ({ getEnvironmentProps }: UseAutocompleteRefsParams) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!(formRef.current && panelRef.current && inputRef.current)) {
      return;
    }
    const { onTouchMove, onTouchStart } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    });

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
    };
  }, [getEnvironmentProps, formRef, panelRef, inputRef]);

  return {
    formRef,
    inputRef,
    panelRef,
  };
};

import { AutocompleteSource, getAlgoliaResults } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { AutocompleteQuerySuggestionsHit } from '@algolia/autocomplete-plugin-query-suggestions/dist/esm/types';
import { createRedirectUrlPlugin } from '@algolia/autocomplete-plugin-redirect-url';
import { createTagsPlugin } from '@algolia/autocomplete-plugin-tags';
import { SearchClient } from 'algoliasearch';
import { ALGOLIA_MAX_RESULTS, ALGOLIA_SOURCE_IDS } from 'constants/algolia';
import { NextRouter } from 'hooks/useRouter';
import { Product } from 'types/Product';
import { QuerySuggestion } from 'types/Search';
import { getAlgoliaIndexName, getSearchUrl } from './algoliaUtil';
import { debounced } from './debounce';

export const getQuerySuggestionsPlugin = (
  locale: string,
  emptyQuerySuggestions: QuerySuggestion[],
  searchClient: SearchClient,
) =>
  createQuerySuggestionsPlugin({
    getSearchParams() {
      return { hitsPerPage: 5 };
    },
    indexName: getAlgoliaIndexName(locale, true),
    searchClient,
    transformSource({
      source,
    }: {
      source: AutocompleteSource<AutocompleteQuerySuggestionsHit>;
    }): AutocompleteSource<AutocompleteQuerySuggestionsHit> {
      const getItemUrl = ({ item }: { item: AutocompleteQuerySuggestionsHit }): string =>
        getSearchUrl(item.query, locale);

      const getItems = ({ query }: { query?: string }) => {
        if (!query?.length) {
          return emptyQuerySuggestions.map((item) => ({
            ...item,
            query: item.suggestion,
          })) as QuerySuggestion[]; // Typecast needed to satisfy the Algolia typing. Far from ideal but the current Algolia solution doesn't allow for an alternative.
        } else if (query.length >= 2) {
          // Retrieve query suggestions from Algolia
          return getAlgoliaResults<AutocompleteQuerySuggestionsHit>({
            queries: [{ indexName: getAlgoliaIndexName(locale, true), query }],
            searchClient,
          });
        } else {
          return []; // Return empty array for query length less than 2
        }
      };

      // Return transformed source data
      return {
        ...source,
        getItemUrl,
        getItems,
      };
    },
  });

export const analyticsTagsPlugin = createTagsPlugin({
  transformSource() {
    return undefined;
  },
});

// Custom Plugin
export const getAlgoliaProductSearchPlugin = (locale: string, searchClient: SearchClient) => ({
  async getSources() {
    await debounced();
    return [
      {
        getItemInputValue({ item }: { item: Product }) {
          return item.name ?? '';
        },
        getItemUrl({ item }: { item: Product }) {
          return `/${item.url}`;
        },
        getItems({ query }: { query?: string }) {
          if (query && query.length >= 3) {
            return getAlgoliaResults({
              queries: [
                {
                  indexName: getAlgoliaIndexName(locale),
                  params: {
                    clickAnalytics: true,
                    hitsPerPage: query.length ? ALGOLIA_MAX_RESULTS.PRODUCTS : 0,
                  },
                  query,
                },
              ],
              searchClient,
            });
          }

          return [];
        },
        sourceId: ALGOLIA_SOURCE_IDS.PRODUCTS_SEARCH,
      },
    ];
  },
});

export const getRedirectUrlPlugin = (router: NextRouter) =>
  createRedirectUrlPlugin({
    onRedirect(redirects) {
      const item = redirects.find((r) => r.sourceId === 'products_search');
      const redirect = item?.urls?.[0];

      if (redirect) {
        router.push?.(redirect, redirect, { shallow: true });
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transformResponse(response: any) {
      const manualRedirect = response?.userData?.find((item: object) => 'redirect' in item)?.redirect;
      const visualRedirect = response?.renderingContent?.redirect?.url;

      return visualRedirect || manualRedirect || undefined;
    },
  });

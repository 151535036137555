import styled, { css } from 'styled-components';
import { breakpointUp, fontSize, spacer, specific, variable, zIndex } from 'theme';
import Container from 'ui/Container/Container';
import { BREAKPOINTS } from '../../../constants/breakpoints';
import CMSUspComponent from '../../CMS/CMSComponent/CMSUspComponent/CMSUspComponent';

const TOP_BAR_NAVIGATION_STYLING = css`
  display: inline-flex;
  gap: ${spacer(50)};
  font-weight: bold;
  margin: 0px;
  padding: 0px;
`;

export const StyledTopBar = styled(Container)`
  background: ${specific('topbar', 'background-color')};
  font-size: ${fontSize(75)};

  && {
    a {
      color: ${specific('topbar', 'text-color')};
      text-decoration: none;
    }
  }
`;

export const StyledTopBarContainer = styled.div`
  border-bottom: ${specific('topbar', 'border-width')} solid ${specific('topbar', 'border-color')};
  position: relative;
  transition: ${variable('transition')};
  z-index: ${zIndex('header')};
  background-color: ${specific('topbar', 'background-color')};
`;

export const StyledDeliveryText = styled(CMSUspComponent)`
  color: ${specific('topbar', 'delivery-color')};
  font-weight: bold;
  display: none;
  white-space: nowrap;

  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      display: flex;
    `,
  )}
`;

export const StyledTopBarNavigationLeft = styled.div`
  ${TOP_BAR_NAVIGATION_STYLING}
`;

export const StyledTopBarNavigationRight = styled.div`
  ${TOP_BAR_NAVIGATION_STYLING}
  text-align: right;
  white-space: nowrap;
`;

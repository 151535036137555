import styled from 'styled-components';
import { color, zIndex } from 'theme';
import { motion } from 'framer-motion';

export const StyledBackdrop = styled(motion.div)`
  background: ${color('shadow-300')};
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.2s ease-in-out;
  transition-delay: 0s;
  z-index: ${zIndex('backdrop')};
  width: 100%;
`;

import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useWebConfig } from 'features/configuration/queries';

import { ALGOLIA_LIMITED_APP_KEY } from 'constants/algolia';
import useRouter from 'hooks/useRouter';
import { searchKeys } from '.';
import { NUMBER_OF_PAGE_RESULTS } from '../../constants/search';
import {
  fetchAlgoliaApiKey,
  fetchQuerySuggestions,
  fetchRefinementValues,
  fetchSearchSuggestions,
  fetchSorts,
  fetchUserIp,
  searchProducts,
  SearchProductsParams,
} from './connector';

const useSortValues = () => {
  const { locale } = useRouter();
  return useQuery({
    queryFn: () => fetchSorts({ locale }),
    queryKey: searchKeys.sortValues({ locale }),
    refetchOnWindowFocus: false,
  });
};

const useRefinementValues = (refinements: string[]) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: refinements?.length > 0,
    queryFn: () => fetchRefinementValues({ locale, refinements }),
    queryKey: searchKeys.refinementValues({ locale, refinements }),
    select: (data) => data?.facets || [],
  });
};

const useSearchProducts = (initialQuery: string, pageQualifier?: string) => {
  const { locale, query } = useRouter();
  const { currentPage, pageSize, q, sort } = query;

  const currentPageParam = Number(currentPage) || 0;
  const pageSizeParam = Number(pageSize) || NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1;
  const qParam = q || '';
  const sortParam = sort || '';
  const initialQueryParam = initialQuery || '';
  const pageQualifierParam = pageQualifier || '';
  const params: SearchProductsParams = {
    currentPage: currentPageParam,
    initialQuery: initialQueryParam,
    locale,
    pageQualifier: pageQualifierParam,
    pageSize: pageSizeParam,
    query: qParam,
    sort: sortParam,
  };

  return useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => searchProducts(params),
    queryKey: searchKeys.search(params),
    refetchOnWindowFocus: false,
  });
};

const useSearchSuggestions = () => {
  const { locale } = useRouter();

  const { data: webConfig } = useWebConfig();

  return useQuery({
    enabled: !!webConfig?.enableSuggestions,
    queryFn: () => fetchSearchSuggestions({ locale }),
    queryKey: searchKeys.searchSuggestions({ locale }),
  });
};

const useQuerySuggestions = (amount = 5) => {
  const { locale } = useRouter();

  return useQuery({
    queryFn: () => fetchQuerySuggestions({ amount, locale }),
    queryKey: searchKeys.querySuggestions({ amount, locale }),
    select: (data) => data?.suggestions || [],
  });
};

const useUserIpAddress = () =>
  useQuery({
    queryFn: () => fetchUserIp(),
    queryKey: searchKeys.userIpAddress(),
  });

const useAlgoliaApiKey = () =>
  useQuery({
    queryFn: () => fetchAlgoliaApiKey(),
    queryKey: searchKeys.algoliaApiKey(),
  });

const useUserIsWhitelisted = () => {
  const { data: algoliaApiKey } = useAlgoliaApiKey();
  if (!algoliaApiKey) return false;
  return algoliaApiKey !== ALGOLIA_LIMITED_APP_KEY;
};

export {
  useAlgoliaApiKey,
  useQuerySuggestions,
  useRefinementValues,
  useSearchProducts,
  useSearchSuggestions,
  useSortValues,
  useUserIpAddress,
  useUserIsWhitelisted,
};

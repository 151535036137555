import { AccountMenuItem } from 'types/Account';
import { isTones } from 'utils/brandUtil';
import { pathnames } from '../i18n/pathnames';
import { ICON_NAMES } from './icon';

const getFieldId = (formId: string, fieldName: string) => `${formId}_${fieldName}`;

export const PROFILE_DATA_FORM_ID = 'account-name-form';

export const MY_ACCOUNT_MENU_ITEMS: AccountMenuItem[] = [
  {
    icon: ICON_NAMES.BARS,
    isLink: true,
    show: true,
    textId: 'my_account_menu_overview',
    url: pathnames.MY_ACCOUNT,
  },
  {
    icon: ICON_NAMES.USER_CIRCLE,
    isLink: true,
    show: true,
    textId: 'my_account_menu_profile_tab',
    url: pathnames.MY_ACCOUNT_INFORMATION,
  },
  {
    hasChildren: true,
    icon: ICON_NAMES.RECEIPT,
    isLink: true,
    show: true,
    textId: 'my_account_menu_invoices_tab',
    url: pathnames.MY_ACCOUNT_ORDERS,
  },
  {
    hasChildren: true,
    icon: ICON_NAMES.HEART,
    isLink: true,
    show: true,
    textId: 'my_account_menu_wishlists_tab',
    url: pathnames.WISHLIST,
  },
  {
    icon: ICON_NAMES.WRENCH,
    isLink: true,
    show: isTones,
    textId: 'my_account_configurations',
    url: pathnames.MY_ACCOUNT_CONFIGURATIONS,
  },
  {
    icon: ICON_NAMES.SLIDERS_V,
    isLink: true,
    show: true,
    textId: 'my_account_menu_preferences_tab',
    url: pathnames.MY_ACCOUNT_PREFERENCES,
  },
];

export const MY_ACCOUNT_HELP_ITEMS = [
  {
    isLink: false,
    show: !isTones,
    textId: 'header_top_nav_services',
    url: pathnames.CONTACT,
  },
  {
    isLink: false,
    show: isTones,
    textId: 'header_top_nav_services',
    url: pathnames.CONTACT_TONES,
  },
];

export const MY_ACCOUNT_ADDRESS_FORM_FIELDS = {
  ADDRESS_TITLE: 'addressTitle',
  CITY: 'city',
  COUNTRY: 'country',
  DEFAULT_ADDRESS: 'defaultAddress',
  DELIVERY_ROUTE: 'delivery_route',
  DELIVERY_STREET_NUMBER: 'delivery_street_number',
  PHONE: 'delivery_phone',
  POBOX: 'poBox',
};

export const PROFILE_DATA_FORM_FIELDS = {
  BIRTHDAY: {
    id: getFieldId(PROFILE_DATA_FORM_ID, 'birthday'),
    label: 'my_account_name_form_birthday',
    name: 'birthday',
  },
  COMPANY_NAME: {
    id: getFieldId(PROFILE_DATA_FORM_ID, 'companyName'),
    label: 'my_account_name_form_company_name',
    name: 'companyName',
  },
  COMPANY_VAT: {
    id: getFieldId(PROFILE_DATA_FORM_ID, 'companyVat'),
    label: 'my_account_name_form_company_vat',
    name: 'companyVat',
  },
  EMAIL: { id: getFieldId(PROFILE_DATA_FORM_ID, 'email'), label: 'my_account_name_form_email', name: 'email' },
  FIRST_NAME: {
    id: getFieldId(PROFILE_DATA_FORM_ID, 'firstName'),
    label: 'my_account_name_form_first_name',
    name: 'firstName',
  },
  LAST_NAME: {
    id: getFieldId(PROFILE_DATA_FORM_ID, 'lastName'),
    label: 'my_account_name_form_last_name',
    name: 'lastName',
  },
  PHONE: { id: getFieldId(PROFILE_DATA_FORM_ID, 'phone'), label: 'my_account_name_form_phone', name: 'phone' },
};

export const CURRENT_LOCATION_FORM_ID = 'location-modal-form';
export const CURRENT_LOCATION_FORM_FIELDS = {
  LOCATION: {
    id: getFieldId(CURRENT_LOCATION_FORM_ID, 'location'),
    label: 'my_account_address_form_location',
    name: 'location',
  },
};

import { useIntl } from 'react-intl';
import { Cart } from 'types/Cart';
import { ICON_NAMES } from '../../../../../constants/icon';
import { pathnames } from '../../../../../i18n/pathnames';
import Box from '../../../../Box/Box';
import Icon from '../../../../Icon/Icon';
import { LocalizedLink } from '../../../../Router';
import { StyledCartButtonCountWrapper, StyledCartButtonCountWrapperText } from './CartButton.styled';

interface CartButtonProps {
  cart?: Cart;
  className?: string;
}

const CartButton = ({ cart, className }: CartButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <LocalizedLink name={pathnames.CART}>
      <Box
        aria-label={formatMessage({ id: 'cart_button_label' })}
        className={className}
        id="cart-button"
        position="relative"
        role="button"
        tabIndex={0}
      >
        <Icon name={ICON_NAMES.CART} size={175} />
        {cart?.totalItems ? (
          <StyledCartButtonCountWrapper>
            <StyledCartButtonCountWrapperText fontSize={88} fontWeight="bold">
              {cart.totalItems}
            </StyledCartButtonCountWrapperText>
          </StyledCartButtonCountWrapper>
        ) : null}
      </Box>
    </LocalizedLink>
  );
};

export default CartButton;

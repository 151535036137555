import Box from 'components/Box/Box';
import Image from 'components/Image/Image';
import { CURRENT_BRAND } from 'constants/brand';
import { MEDIA_URL } from 'constants/media';
import { useWebConfig } from 'features/configuration/queries';
import useRouter from 'hooks/useRouter';

interface Props {
  className?: string;
}

const ShopLogo = ({ className }: Props) => {
  const { locale } = useRouter();
  const { data: webConfig } = useWebConfig();
  const webConfigLogoUrl = webConfig?.companyLogo?.url;
  const fallbackLogoUrl = `${MEDIA_URL}/static/logo/${CURRENT_BRAND}/logo_${locale}.svg`;
  const logoUrl = webConfigLogoUrl || fallbackLogoUrl;

  return (
    <Box className={className} fullHeight fullWidth position="relative">
      <Image alt="Logo" layout="fill" objectFit="contain" objectPosition="left center" priority src={logoUrl} />
    </Box>
  );
};

export default ShopLogo;

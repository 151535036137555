import styled, { css } from 'styled-components';
import { spacer, specific, variable } from 'theme';

interface StyledLinkProps {
  showDivider?: boolean;
}

export const StyledLink = styled.a<StyledLinkProps>`
  text-decoration: none;
  font-weight: bold;
  position: relative;
  color: ${specific('mainbar', 'text-color')} !important;

  ${({ showDivider }) =>
    showDivider &&
    css`
      &::after {
        content: '';
        ${variable('border-right')};
        border-color: ${specific('mainbar', 'text-color')};
        height: ${spacer(200)};
        position: absolute;
        left: ${spacer(200)};
        top: 50%;
        transform: translateY(-50%);
      }
    `}
`;

import algoliasearch from 'algoliasearch';
import { ALGOLIA_LIMITED_APP_KEY, ALGOLIA_PUBLIC_APP_ID } from 'constants/algolia';
import { useAlgoliaApiKey } from 'features/search/queries';
import { useMemo } from 'react';

export const useAlgoliaSearchClient = () => {
  const { data: fetchedAlgoliaApiKey } = useAlgoliaApiKey();
  const algoliaAppId = ALGOLIA_PUBLIC_APP_ID;
  const algoliaApiKey = fetchedAlgoliaApiKey ?? ALGOLIA_LIMITED_APP_KEY;

  const searchClient = useMemo(() => algoliasearch(algoliaAppId, algoliaApiKey), [algoliaApiKey, algoliaAppId]);

  return { searchClient };
};

const debouncePromise = <T>(fn: (...args: T[]) => Promise<T>, time: number) => {
  let timerId: NodeJS.Timeout | undefined = undefined;

  return (...args: T[]) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise<T>((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    });
  };
};

export const debounced = debouncePromise((items: unknown[]) => Promise.resolve(items), 500);

import { useEffect } from 'react';
import { useScrollLock } from 'usehooks-ts';

interface UseLockBodyScrollParams {
  condition?: boolean;
  options?: Parameters<typeof useScrollLock>[0];
}

const useLockBodyScroll = ({ condition, options = { autoLock: false } }: UseLockBodyScrollParams) => {
  const { lock, unlock } = useScrollLock(options);

  useEffect(() => {
    if (condition) {
      lock();
      document.body.style.position = 'fixed';
      document.body.style.left = '0';
      document.body.style.right = '0';
      document.body.style.top = '0';
      document.body.style.bottom = '0';
    } else {
      unlock();
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.left = '';
      document.body.style.right = '';
      document.body.style.bottom = '';
    }
  }, [condition]);

  return null;
};

export default useLockBodyScroll;

import React from 'react';
import { DEFAULT_TRANSITION_TIMING_FAST } from '../../constants/animations';
import { StyledBackdrop } from './Backdrop.styled';
import { DROPDOWN_ANIMATION } from '../../constants/animations';

interface BackdropProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  show?: boolean;
}

const Backdrop = ({ children, className, show = true, ...props }: BackdropProps) => (
  <>
    {show && (
      <StyledBackdrop
        className={className}
        role="button"
        tabIndex={0}
        initial="collapsed"
        animate="expanded"
        variants={DROPDOWN_ANIMATION}
        transition={DEFAULT_TRANSITION_TIMING_FAST}
        {...props}
      />
    )}

    {children}
  </>
);

export default Backdrop;

import Icon from 'components/Icon/Icon';
import { ICON_NAMES } from 'constants/icon';
import { CMSLinkSlot } from 'types/CMS';
import CMSLink from './CMSLink/CMSLink';

interface CMSLinksProps {
  callback?: () => void;
  className?: string;
  extraLinks?: React.ReactNode[];
  linkSlots?: CMSLinkSlot[];
  position: string;
  withIcon?: boolean;
}

const CMSLinks = ({ callback, className, extraLinks, linkSlots, position, withIcon }: CMSLinksProps) => (
  <>
    {linkSlots
      ?.find((slot) => slot?.position === position)
      ?.components?.map((component, index) => {
        if (component?.localizedName && (component?.url || component?.externalUrl)) {
          return (
            <CMSLink key={index} callback={callback} linkComponent={component} className={className}>
              {withIcon ? <Icon type="fa" name={ICON_NAMES.ANGLE_RIGHT} size={100} /> : <></>}
            </CMSLink>
          );
        }

        return null;
      })}

    {extraLinks?.map((extraLink) => extraLink)}
  </>
);

export default CMSLinks;

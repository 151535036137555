import { isWebview } from 'constants/environment';
import dynamic from 'next/dynamic';
import { ReactNode, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'ui/Button/Button';
import Container from 'ui/Container/Container';
import Header from './Header/Header';

const Footer = dynamic(() => import('./Footer/Footer'));

interface Props {
  children: ReactNode;
  className?: string;
  footerMinimal?: boolean;
  headerMinimal?: boolean;
  hideLanguageSwitcher?: boolean;
  showFooterOnlyCompanyInfo?: boolean;
  showHeaderEmptyBanner?: boolean;
  showPaymentMethods?: boolean;
  withSpace?: boolean;
}

const PageLayout = ({
  children,
  className,
  footerMinimal,
  headerMinimal,
  hideLanguageSwitcher,
  showFooterOnlyCompanyInfo,
  showHeaderEmptyBanner,
  showPaymentMethods = true,
  withSpace,
}: Props) => {
  const { formatMessage } = useIntl();

  const pageContentRef = useRef<HTMLDivElement>(null);

  return (
    <div className={className}>
      <Button
        className="absolute left-[-9999px] z-header-overwrite m-2 focus-visible:left-auto"
        variant="secondary"
        onClick={() => {
          if (pageContentRef?.current) {
            pageContentRef.current.focus({ preventScroll: true });
          }
        }}
      >
        {formatMessage({ id: 'general_skip_link_to_main_content' })}
      </Button>

      {!isWebview && (
        <Header
          hideLanguageSwitcher={hideLanguageSwitcher || headerMinimal}
          minimal={headerMinimal}
          showEmptyBanner={showHeaderEmptyBanner}
        />
      )}
      <Container ref={pageContentRef} tabIndex={0} className={!isWebview && withSpace ? 'py-[2.5rem]' : ''}>
        {children}
      </Container>
      {!isWebview && (
        <Footer
          minimal={footerMinimal}
          showOnlyCompanyInfo={showFooterOnlyCompanyInfo}
          showPaymentMethods={showPaymentMethods}
        />
      )}
    </div>
  );
};

export default PageLayout;

import { useIntl } from 'react-intl';
import { ICON_NAMES } from '../../../../constants/icon';
import { pathnames } from '../../../../i18n/pathnames';
import Icon from '../../../Icon/Icon';
import { LocalizedLink } from '../../../Router';

type StoreButtonProps = {
  className?: string;
};

const StoreButton = ({ className }: StoreButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <LocalizedLink className={className} name={pathnames.STOREFINDER}>
      <div aria-label={formatMessage({ id: 'store_button_label' })} role="button" tabIndex={0}>
        <Icon name={ICON_NAMES.STORE} size={175} />
      </div>
    </LocalizedLink>
  );
};

export default StoreButton;
